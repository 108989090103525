






import {Component, Vue} from "vue-property-decorator";
import {ReceiptType} from "../../../../constants/receipt";
import ReceiptForm from "@/views/cash-book/receipt/receipt-form.vue";

@Component({
    components: {
        ReceiptForm
    }
})
export default class ReceiverVoucherForm extends Vue {
    receiptType = ReceiptType;
}
